import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'
import ImageHeroResources from '../images/resources/hero-resources.png'
import DownloadAction from '../components/DownloadAction'
import StaticDownloadAction from '../components/StaticDownloadAction'
import ModalLayout from '../components/Modals/ModalLayout'
import InterstitialModal from '../components/Modals/InterstitialModal'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ExternalLink from '../components/ExternalLink'

type ListItemProps = {
	title: string
	url: string
	urlTitle: string
}
const ListItem = ({ title, url, urlTitle }: ListItemProps) => {
	return (
		<div className='bg-white shadow cursor-pointer flex justify-between h-full items-center text-mediumVioletRed rounded-lg p-5 lg:mb-0 mb-4'>
			<div>
				<div className='text-pompadourViolet font-bold'>{title}</div>
				<span className='underline text-mediumVioletRed lg:text-sm md:text-xs text-sm'>
					{urlTitle}
				</span>
			</div>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				className='h-6 w-6'
				fill='none'
				viewBox='0 0 24 24'
				stroke='currentColor'
				strokeWidth='2'
			>
				<path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7' />
			</svg>
		</div>
	)
}
const ListItemData = [
	{
		title: 'The Exon 20 Group',
		url: 'https://www.exon20group.org/',
		urlTitle: 'exon20group.org',
	},
	{
		title: 'GO₂ Foundation for Lung Cancer',
		url: 'https://www.go2foundation.org/',
		urlTitle: 'go2foundation.org',
	},
	{
		title: 'CancerCare',
		url: 'https://www.cancercare.org/caregiving',
		urlTitle: 'cancercare.org/caregiving',
	},
	{
		title: 'EGFR Resisters',
		url: 'https://www.egfrcancer.org/',
		urlTitle: 'egfrcancer.org',
	},
	{
		title: 'Lung Cancer Research Foundation',
		url: 'https://www.lungcancerresearchfoundation.org/',
		urlTitle: 'lungcancerresearchfoundation.org',
	},
	{
		title: 'Cancer Support Community',
		url: 'https://www.cancersupportcommunity.org/',
		urlTitle: 'cancersupportcommunity.org',
	},
	{
		title: 'LUNGevity Foundation',
		url: 'https://www.lungevity.org/',
		urlTitle: 'lungevity.org',
	},
	{
		title: 'Lung Cancer Foundation of America',
		url: 'https://www.lcfamerica.org/',
		urlTitle: 'lcfamerica.org',
	},
	{
		title: 'Patient Advocate Foundation',
		url: 'https://www.patientadvocate.org/',
		urlTitle: 'patientadvocate.org',
	},
	{
		title: 'National Lung Cancer Roundtable',
		url: 'https://www.nlcrt.org/',
		urlTitle: 'nlcrt.org',
	},
	{
		title: 'American Cancer Society',
		url: 'https://www.cancer.org/cancer/lung-cancer.html',
		urlTitle: 'cancer.org/cancer/lung-cancer.html',
	},
]

type Props = {
	data: any
	location: Location
}

const Resources = ({ data, location }: Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'Resources | EXKIVITY® (mobocertinib)',
					description:
						'Download EXKIVITY® (mobocertinib) resources for people on or considering treatment and their care partners—including treatment guides and support group links. Please see the Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>

			<Hero bgImage={data.heroImage} imageAlt=''>
				<h1 className='text-white'>Useful Resources</h1>
			</Hero>
			<div className='grid grid-cols-12' id='possible-side-effects-container'>
				<div className='col-start-2 col-span-10 my-8'>
					<h2 className='text-pompadourViolet font-bold md:text-2xl text-xl mb-2'>
						Resources to download
					</h2>
					<div>
						Whether you are planning to talk to your healthcare team about EXKIVITY,
						are just starting treatment, or are caring for a loved one with epidermal
						growth factor receptor (EGFR) Exon20 insertion-positive metastatic
						non-small cell lung cancer (NSCLC), the information below can help you
						become an active participant in the treatment process.
					</div>
				</div>
				<div className='col-start-2 col-span-10'>
					<div className='xl:grid xl:grid-cols-3 2xl:gap-7 xl:gap-5 md:gap-2'>
						<StaticDownloadAction
							text='Patient Brochure'
							containerClass='resources'
							fileURL='/brochures/Exkivity Patient Education Brochure.pdf'
							images={{
								desktop:
									'/resources-patient-brochure.webp',
								mobile:
									data.downloadPatientBrochureMobile.childImageSharp.gatsbyImageData,
							}}
						/>
						<StaticDownloadAction
							text='Treatment Guide'
							containerClass='resources'
							fileURL='/brochures/Exkivity Patient Treatment Guide.pdf'
							images={{
								desktop:
									'/resources-treatment-guide.webp',
								mobile:
									data.downloadTreatmentGuideMobile.childImageSharp.gatsbyImageData,
							}}
						/>
						<StaticDownloadAction
							text='Care Partner Brochure'
							containerClass='resources'
							fileURL='/brochures/Exkivity Patient Care Partner Brochure.pdf'
							images={{
								desktop:
									'/resources-care-partner-brochure.webp',
								mobile:
									data.downloadCarePartnerBrochureMobile.childImageSharp.gatsbyImageData,
							}}
						/>
					</div>
				</div>
			</div>
			<div className='bg-gradient-to-r from-weakYellow to-rose py-10 grid grid-cols-12'>
				<div className='col-start-2 col-span-10'>
					<div className='my-8'>
						<h2 className='text-pompadourViolet font-bold md:text-2xl text-xl mb-2'>
							Where to go for more information
						</h2>
						<div>
							The organizations below can help you find more information and answers
							about
							<span className='text-mediumVioletRed font-bold'>
								{' '}
								EGFR Exon20 insertion
							</span>{' '}
							mutations and metastatic NSCLC. They can also help you find a{' '}
							<span className='text-mediumVioletRed font-bold'>
								community ready to help and support you
							</span>
							.
						</div>
					</div>
					<div className='lg:grid lg:grid-cols-3 gap-5'>
						{ListItemData.map((item, index) => {
							return (
								<ExternalLink href={item.url} key={index}>
									<ListItem title={item.title} url={item.url} urlTitle={item.urlTitle} />
								</ExternalLink>
							)
						})}
					</div>
					<div className='mt-16 mb-20'>
						The manufacturer does not endorse these organizations. Takeda is not
						responsible for content provided by any third-party organization. The
						online resources are provided for informational purposes only and are not
						meant to replace the medical advice of your healthcare providers.
					</div>
				</div>
			</div>
			<div className='grid grid-cols-12'>
				<div
					className='col-start-2 col-span-10'
					id='possible-side-effects-container'
				>
					<div className='mb-8 mt-16'>
						<h2 className='text-pompadourViolet font-bold md:text-2xl text-xl mb-2'>
							Glossary
						</h2>
						<div className='mb-6'>
							<span className='text-mediumVioletRed font-bold'>EGFR:</span> EGFR stands
							for epidermal growth factor receptor. It is a protein found on the
							surface of some cells. Normally, it helps control how cells grow and
							divide. If it becomes mutated, it can cause cells to grow out of control,
							which can cause cancer.
						</div>
						<div className='mb-6'>
							<span className='text-mediumVioletRed font-bold'>Insertion:</span> An
							insertion mutation means a bit of DNA has accidentally been added to one
							of your genes. This insertion may cause the gene to no longer work
							properly.
						</div>
						<div className='mb-6'>
							<span className='text-mediumVioletRed font-bold'>Metastatic:</span> If
							cancer is metastatic, that means it has spread from the place where it
							started to other parts of the body.
						</div>
						<div className='mb-6'>
							<span className='text-mediumVioletRed font-bold'>Mutation:</span> A
							mutation is a change in the DNA of a cell. Mutations can happen by
							mistake when cells are growing and dividing. They can also happen because
							of exposure to something that damages the DNA. Some mutations may lead to
							cancer, but others can be beneficial or have no effect.
						</div>
						<div className='mb-6'>
							<span className='text-mediumVioletRed font-bold'>NSCLC:</span> Non-small
							cell lung cancer is a type of lung cancer named for how the cells look
							under a microscope. There are different subtypes. The main subtypes are
							squamous cell carcinoma, large cell carcinoma, and adenocarcinoma. These
							subtypes are named after the types of cells where the cancer starts.
						</div>
						<div className='mb-6'>
							<span className='text-mediumVioletRed font-bold'>
								Targeted therapies:
							</span>{' '}
							A type of treatment that identifies and attacks specific types of cancer
							cells with less harm to normal cells. These targeted therapies block the
							actions of proteins on, or in, the cancer cell that control how the cell
							grows and divides.
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
export default Resources

export const query = graphql`
	{
		heroImage: file(relativePath: { eq: "resources/hero-resources.png" }) {
			...heroImage
		}

		downloadPatientBrochureMobile: file(
			relativePath: { eq: "resources-patient-brochure-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadPatientBrochureDesktop: file(
			relativePath: { eq: "resources-patient-brochure.png" }
		) {
			...downloadActionImage
		}

		downloadTreatmentGuideMobile: file(
			relativePath: { eq: "resources-treatment-guide-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadTreatmentGuideDesktop: file(
			relativePath: { eq: "resources-treatment-guide.png" }
		) {
			...downloadActionImage
		}

		downloadCarePartnerBrochureMobile: file(
			relativePath: { eq: "resources-care-partner-brochure-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadCarePartnerBrochureDesktop: file(
			relativePath: { eq: "resources-care-partner-brochure.png" }
		) {
			...downloadActionImage
		}
	}
`
